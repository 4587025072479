<template>
    <draggable tag="ul"  :list="comp.list"  class="treelist" handle=".handle" item-key="idx" @start="comp.dragging = true" @end="comp.dragging = false" >
       <template #item="{ element }">
            <li class="list pointer" v-if="comp.list.length>0">
                <button class="btn-move handle"></button>
                <button class="btn-menubox" :class="{ 'active':element.idx==comp.selected }" @click="comp.update(element)">{{ element.kname }}</button>
                <button class="btn-menuAdd"  v-if="element.is_team=='N' " @click="comp.showAddTeam(element.idx)">+ 팀 추가</button> <!-- 본부deptcode인경우 팀 추가버튼 생성-->
              <CarrotSubTreeItem v-model="comp.selected" :tree="element.children" ></CarrotSubTreeItem>
              <ul class="treelist">
                <li class="list list-add" v-if="comp.selected==element.idx&&comp.show_add_team==true">
                  <button class="btn-move"></button>
                  <input type="text" class="input-menubox" v-model="comp.add_name" placeholder="팀명을 입력해주세요.">
                  <button class="btn-default" @click="comp.addTeam(element.idx)">추가</button>
                  <button class="btn-default" @click="comp.selected=0;comp.show_add_team=false">취소</button>
                </li>
              </ul>
            </li>
        </template>
    </draggable>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import {useToast} from "vue-toastification";
import axios from '@/plugins/axios.js'

 import CarrotSubTreeItem from '@/components/common/CarrotSubTreeItem.vue';
export default {
    name: 'CarrotTreeItem',
    components: {
        CarrotSubTreeItem,
        draggable
    },
    props: {
        modelValue: {
            type: [String, Number],
            default: 0,
        },
        tree: {
            type: [Array, String],
            default: () => []
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const toast  = useToast();
        const comp = reactive({
            list     : [],
            selected : 0,
            dragging : false,

            update: (e) => {
                comp.selected = e.idx;
                comp.sType = "Team";
                emit('update:modelValue', comp.selected);
            },
            showAddTeam : (idx) => {
              comp.selected = idx;
              comp.show_add_team = true;
              console.log(comp.selected, comp.show_add_team);
            },
          addTeam : (idx) => {
            if( comp.isAuth == false ){
              Swal.fire({
                title : '본부/팀 관리',
                text  : '접근 권한이 없습니다.'
              });
              return;
            }

            let params = {
              pidx  : idx,
              kname : comp.add_name
            };

            if( params.kname == "" ){
              toast.error("팀명을 입력해주세요.");
              return;
            }

            axios.post("/rest/mypims/addTeam", params).then((res) => {
              if( res.data.err == 0 ){
                comp.update(comp.selected);
              } else {
                if(res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          },
            doSubmit: (seq) => {
                let params = {
                    idx : comp.list[0].p_deptcode,
                    seq : seq
                };

                axios.post("/rest/mypims/modSequence", params).then((res) => {
                    if( res.data.err == 0 ){
                      //
                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

        });
        watch(() => props.modelValue, (nVal) => {
            comp.selected = nVal;
        });

        watch(() => props.tree, (nVal) => {
            comp.list = nVal;
        });

        watch(() => comp.dragging, (nVal, oVal) => {
            if( nVal == false && oVal == true ){
                let seq = [];
                let is_modified = false;
                for(let i=0; i<comp.list.length; i++){
                    let irow = comp.list[i];
                    seq.push(irow.idx);
                    if( (i+1) != irow.seq ){
                        is_modified = true;
                    }
                }
                if( is_modified == true ){
                    comp.doSubmit(seq);
                }
            }
        });


        watch(() => comp.selected, () => {
          comp.show_add_team = false;
          emit('update:modelValue', comp.selected);
        });

        onMounted(() => {
            // Mounted
            comp.list     = props.tree;
            comp.selected = props.modelValue;
        });
        return {comp};
    }
}
</script>
<style lang="scss" scoped>
</style>