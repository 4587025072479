<template>
    <draggable tag="ul"  :list="comp.list"  class="treelist"  handle=".handle"  item-key="idx"  @start="comp.dragging = true"  @end="comp.dragging = false" >
        <template #item="{ element }">
            <li class="list pointer" v-if="comp.list.length>0">
                <button class="btn-move handle"></button>
                <button class="btn-menubox" :class="{ 'active':element.idx==comp.selected }" @click="comp.update(element)">{{ element.kname }}</button>
            </li>
        </template>
    </draggable>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import draggable from "vuedraggable";
import axios from 'axios'

export default {
    name: 'CarrotSubTreeItem',
    components: {
        draggable
    },
    props: {
        modelValue: {
            type: [String, Number],
            default: 0,
        },
        tree: {
            type: [Array, String],
            default: () => []
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const comp = reactive({
            list     : [],
            selected : 0,
            dragging : false,

            update: (e) => {
                comp.selected = e.idx;
                comp.sType = "Team";
                emit('update:modelValue', comp.selected);
            },
            showAddTeam : (idx) => {
              comp.selected = idx;
              comp.show_add_team = true;
              console.log(comp.selected, comp.show_add_team);
            },
            doSubmit: (seq) => {
                let params = {
                    idx : comp.list[0].p_deptcode,
                    seq : seq
                };

                axios.post("/rest/mypims/modSequence", params).then((res) => {
                    if( res.data.err == 0 ){
                      //
                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

        });

        watch(() => props.modelValue, (nVal) => {
            comp.selected = nVal;
        });

        watch(() => props.tree, (nVal) => {
            comp.list = nVal;
        });

        watch(() => comp.dragging, (nVal, oVal) => {
            if( nVal == false && oVal == true ){
                let seq = [];
                let is_modified = false;
                for(let i=0; i<comp.list.length; i++){
                    let irow = comp.list[i];
                    seq.push(irow.idx);
                    if( (i+1) != irow.seq ){
                        is_modified = true;
                    }
                }
                if( is_modified == true ){
                    comp.doSubmit(seq);
                }
            }
        });

        onMounted(() => {
            // Mounted
            comp.list     = props.tree;
            comp.selected = props.modelValue;
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
</style>